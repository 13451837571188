import React, { Component } from 'react';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';
 
class ImgGallery extends Component{
    constructor() {
        super();
        this.state = { currentImage: 0 };
        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
    }

    openLightbox(event, obj) {
        this.setState({
          currentImage: obj.index,
          lightboxIsOpen: true,
        });
    }
    
    closeLightbox() {
        this.setState({
          currentImage: 0,
          lightboxIsOpen: false,
        });
    }
    
    gotoPrevious() {
        this.setState({
          currentImage: this.state.currentImage - 1,
        });
    }
    
    gotoNext() {
        this.setState({
          currentImage: this.state.currentImage + 1,
        });
    }

    render() {
        window.scrollTo(0, 0);
        return(
            <div className='text-center cardPad'>
                    <h1 className='center-block title pacific'>Gallery</h1>
                <Gallery photos={photos} onClick={this.openLightbox} />
                <Lightbox   images={photos}
                            onClose={this.closeLightbox}
                            onClickPrev={this.gotoPrevious}
                            onClickNext={this.gotoNext}
                            currentImage={this.state.currentImage}
                            isOpen={this.state.lightboxIsOpen}
                />
            </div>
        );
    }
};

const photos = [
        {
            src:'./assets/sprinkle1.jpg',
            width: 3,
            height: 2,
            alt: "a picture of a sprinkle cupcake"
        },
        {
            src: './assets/canole.jpg',
            width: 3,
            height: 2,
            alt: "a picture of a canole"
        },
        {
            src:'./assets/sprinkle2.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a sprinkle cupcake"
        },
        {
            src:'./assets/graham.jpg',
            width: 750,
            height: 500,
            alt: "a picture of chocolate covered graham crackers"
        },
        {
            src: './assets/ladyfingers.jpg',
            width: 3,
            height: 2,
            alt: "a picture of ladyfinger cookies"
        },
        {
            src:'./assets/thumbprints.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of thumbprint cookies"
        },
        {
            src:'./assets/toffee.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of toffee"
        },
        {
            src:'./assets/mocharolls.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of mocha rolls"
        },
        {
            src:'./assets/eclair.jpg' ,
            width: 750,
            height: 500,
            alt: "a picture of an ecalir"
        },
        {
            src:'./assets/chocchewy.jpg' ,
            width: 750,
            height: 500,
            alt: "a picture of choc chewy cookies"
        },
        {
            src:'./assets/danish.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a danish"
        },
        {
            src:'./assets/limetart.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a keylime tart"
        },
        {
            src: './assets/bluescone.jpg',
            width: 3,
            height: 2,
            alt: "a picture of a blueberry scone"
        },
        {
            src:'./assets/bearclaw.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a bearclaw danish"
        },
        {
            src:'./assets/pecanscone.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a pecan scone"
        },
        {
            src:'./assets/pecanbar.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a pecan bar danish"
        },
        {
            src:'./assets/choccroissant.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a chocolate croissant"
        },
        {
            src:'./assets/croissant.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a butter croissant"
        },
        {
            src:'./assets/challah.jpg' ,
            width: 3,
            height: 2,
        },
        {
            src:'./assets/sesamechallah.jpg',
            width: 3,
            height: 2,
            alt: "a picture of sesame challah bread"
        },
        {
            src:'./assets/florentine.jpg',
            width: 3,
            height: 2,
            alt: "a picture of a florentine cookie"
        },
        {
            src:'./assets/chocmuffin.jpg',
            width: 3,
            height: 2,
            alt: "a picture of a chocolate chip muffin"
        },
        {
            src:'./assets/flower2.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a flower cupcake"
        },
        {
            src:'./assets/flower1.jpg',
            width: 3,
            height: 2,
            alt: "a picture of a flower cupcake"
        },
        {
            src:'./assets/tart5.jpg',
            width: 3,
            height: 2,
            alt: "a picture of a fruit tart"
        },
        {
            src:'./assets/tart4.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a fruit tart"
        },
        {
            src:'./assets/tart3.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a fruit tart"
        },
        {
            src:'./assets/tart9.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a fruit tart"
        },
        {
            src:'./assets/tart2.jpg',
            width: 2,
            height: 1,
            alt: "a picture of a fruit tart"
        },
        {
            src:'./assets/PecanDanish.jpg' ,
            width: 750,
            height: 500,
            alt: "a picture of a pecan danish"
        },
        {
            src:'./assets/CoffeeCake.jpg' ,
            width: 750,
            height: 500,
            alt: "a picture of a coffee cake"
        },
        {
            src:'./assets/squaredanish2.jpg',
            width: 500,
            height: 500,
            alt: "a picture of a danish"
        },
        {
            src:'./assets/squaredanish.jpg',
            width: 500,
            height: 500,
            alt: "a picture of a danish"
        },
        {
            src:'./assets/creampuff.jpg' ,
            width: 750,
            height: 500,
            alt: "a picture of a creampuff"
        },
        {
            src:'./assets/sqcake7.jpg' ,
            width: 500,
            height: 500,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/CS-124.jpg' ,
            width: 750,
            height: 500,
            alt: "a picture of a cake slice"
        },
        {
            src:'./assets/DONUTS.jpg' ,
            width: 500,
            height: 500,
            alt: "a picture of various donuts"
        },
        {
            src:'./assets/choccheesecake.jpg' ,
            width: 1.25,
            height: 1,
            alt: "a picture of chocolate cheesecake"
        },
        {
            src:'./assets/CS-080.jpg' ,
            width: 1.25,
            height: 1,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/CS-092.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/CS-100.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/parisiantorte.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
         {
            src:'./assets/boxcake.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/pinkstripes.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/princesstorte.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/cheesecake.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/germanchoc.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/carrotcake.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/chocostrawberry.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/lego.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/monstertruck.jpg' ,
            width: 4,
            height: 5,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/lizardcake.jpg' ,
            width: 2,
            height: 3,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/owlcake.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/shellcake.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/simplegrad.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src: './assets/redslippers.jpg',
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src: './assets/barrel.jpg',
            width: 2,
            height: 3,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/TROPICALTHEME.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/boat.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/guitarcutout.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/happyfourth.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/60cutout.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/football.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/piano.jpg' ,
            width: 5,
            height: 4,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/retro50.jpg' ,
            width: 2,
            height: 3,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/ringbox.jpg' ,
            width: 2,
            height: 3,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/rocketcutout.jpg' ,
            width: 2,
            height: 3,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/soccerrose.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/whalecutout.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/secret.jpg' ,
            width: 2,
            height: 3,
            alt: "a picture of a cake"
        },
        {
            src: './assets/CS-095.jpg',
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/flowertier.jpg' ,
            width: 2,
            height: 3,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/creamtier.jpg' ,
            width: 2,
            height: 3,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/elephanttier.jpg' ,
            width: 2,
            height: 3,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/teatier.jpg' ,
            width: 2,
            height: 3,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/masktier.jpg' ,
            width: 2,
            height: 3,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/rosetier.jpg' ,
            width: 1.25,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/paddingtier.jpg' ,
            width: 1.25,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/shelltier.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src: './assets/platetier.jpg',
            width: 500,
            height: 500,
            alt: "a picture of a cake"
        },
        {
            src: './assets/trexcake.jpg',
            width: 2.5,
            height: 3,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/offsettier.jpg' ,
            width: 500,
            height: 500,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/rainbowtier.jpg' ,
            width: 500,
            height: 500,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/boxtier.jpg' ,
            width: 2,
            height: 3,
            alt: "a picture of a cake"
        },
        {
            src: './assets/circustier.jpg',
            width: 2,
            height: 3,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/blossomtier.jpg' ,
            width: 3,
            height: 2,
            alt: "a picture of a cake"
        },
        {
            src:'./assets/rustictier.jpg' ,
            width: 2,
            height: 3,
            alt: "a picture of a cake"
        }
];

export default ImgGallery;