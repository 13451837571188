import React, { Component } from 'react';
import Intro from './IntroTile';
import Cakes from './CakesTile';
import Baked from './BakedTile';
import Food  from './FoodTile';
import Event from './EventTile';



class Home extends Component {
  render(){
    window.scrollTo(0, 0);
    return (
      <div>
        <Intro/>
        <Cakes/>
        <Food/>
        <Baked/> 
        <Event/>  
      </div>  
    )
  }
};

export default  Home ;