import React, { Component } from 'react';


class BreakfastPage extends Component {
  render(){
    window.scrollTo(0, 0);
    return(
      <div className='card cardPad shortPage'>

        <h1 className='text-center title pacific'>Breakfast</h1>
        <div className="menu">
            <div className='menuItem'>
                <h4 className='title'>Breakfast Croissant</h4> 
                <p className='blurbs'>2 Eggs with your choice of meat and cheese, avalible with avocado or double meat</p>
                <p className='blurbs'>Meat Choices: Bacon, or Sausage</p>
            </div>
            
            <div className='menuItem'>
                <h4 className='title'>Breakfast Brioche</h4> 
                <p className='blurbs'>2 Eggs with your choice of meat and cheese, avalible with avocado or double meat</p>
                <p className='blurbs'>Meat Choices: Bacon, or Sausage</p>
            </div>

            <div className='menuItem'>
                <h4 className='title'>Breakfast Burrito</h4> 
                <p className='blurbs'>2 Eggs Hashbrowns Cheese and your choice of meat, avalible with avocado or double meat</p>
                <p className='blurbs'>Meat Choices: Bacon, or Sausage</p>
            </div>
            
            <div className='menuItem'>
                <h4 className='title'>Vegetarian</h4> 
                <p className='blurbs'>Cheddar cheese, Swiss cheese, Avocado, Onion, Lettuce, Tomato, Olives, Pepperoncini, Mustard, mayonnaise on White or Wheat Roll.</p>
            </div>
          </div>
      </div>
      )}
  };


export default  BreakfastPage;