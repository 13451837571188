import React, { Component } from 'react';

class Contact extends Component {
  render(){
    window.scrollTo(0, 0);
    return(
    <div className="card cardPad shortPage">
      <h1 className='text-center title pacific'>Contact Us</h1>
        <br/> 
      <p className='blurbs text-center'>310 377-7846</p>
        <br/>
      <p className='blurbs text-center'>MayersBakery@aol.com</p>
        <br/>
      <p className='blurbs text-center'>14 Peninsula Center, Rolling Hills Estates, 90274</p>
        <br/>
      <h3 className="blurbs text-center">Hours</h3>
        <br/>
      <p className="blurbs text-center"> Monday - Saturday: 7:00am - 5:00pm </p>
        <br/>  
      <p className="blurbs text-center"> Sunday: 8:00am - 2:00pm </p>
        <br/>  
    </div>
    )
  }
};

export default Contact;