import React, { Component } from 'react';

class Map extends Component {
  render(){
    return (

      <div id="bakeryMap">
       {/* <iframe width="100%" height="250" seamless='seamless' title='bakery location on map' frameBorder='0' src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDZLRrztGdIh6laOEi4ICfJKvBTWxVT748&q=14+Peninsula+Center,+Rolling+Hills+Estates,+CA+90274-3506"></iframe> */}
      </div>

      )
  }
}; 

export default Map;