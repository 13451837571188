import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class Navbar extends Component{

    constructor(props){
        super(props);

        this.state = { toggle: false };
    }

    navBarToggle = () => {
        this.setState(state => ({
            toggle: !state.toggle
        }));
    }

    render(){
        return (
            <nav className="navbar">
                    <span className="navbar-toggle" id="js-navbar-toggle" onClick={this.navBarToggle}>
                        <i className="fas fa-bars"></i>
                    </span>
                    <Link to="/" className="logo">
                        <img id='logo' src="./assets/mayerslogonew.png" alt="logo" />
                    </Link>
                    <ul className={this.state.toggle ? "main-nav-show" : "main-nav-hide"} id="js-menu">
                        <li>
                            <Link to="breakfast" className="nav-links">Breakfast</Link>
                        </li>
                        <li>
                            <Link to="lunch" className="nav-links">Lunch</Link>
                        </li>
                        <li>
                            <Link to="cakes" className="nav-links">Cakes</Link>
                        </li>
                        <li>
                            <Link to="pastry" className="nav-links">Pastries</Link>
                        </li>
                        <li>
                            <Link to="bread" className="nav-links">Bread</Link>
                        </li>
                        <li>
                            <Link to="gallery" className="nav-links">Gallery</Link>
                        </li>
                        <li>
                            <Link to="events" className="nav-links">Events</Link>
                        </li>
                        <li>
                            <Link to="contact" className="nav-links">Contact Us</Link>
                        </li>
                        <li>
                            <a className="nav-links" href="https://www.instagram.com/mayersbakery/" alt="link to mayers bakery instagram page">
                                <i className="fab fa-instagram" alt='instagram' aria-hidden="true"></i>
                            </a>
                        </li>
                    </ul>
            </nav>




            // <nav className="navbar navbar-default navbar-static-top container-fluid text-center" id='myNav' >
            //     <Link to="/" className='visible-xs'>
            //       <img className="center-block" id='logo' src="./assets/mayerslogonew.png" />
            //     </Link>

            //     <div className='row'>
            //       <button type="button" className=" navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
            //           <span className="sr-only">Toggle navigation</span>
            //           <span>Menu</span>
            //       </button>
            //     </div>
                
            //     <div id="navbar" className="navbar-collapse collapse">
            //       <ul className=" nav navbar-nav text-center visible-xs" aria-expanded="false">
            //         <li data-toggle="collapse" data-target="#navbar">
            //           <Link to="/cakemenu">Cakes</Link>
            //         </li>
            //         <li data-toggle="collapse" data-target="#navbar">
            //           <Link to="/pastrymenu">Pastries</Link>
            //         </li>
            //         <li data-toggle="collapse" data-target="#navbar">
            //           <Link to="/breadmenu">Breads</Link>
            //         </li>
            //         <li data-toggle="collapse" data-target="#navbar">
            //           <Link to="/lunchmenu">Lunch</Link>
            //         </li>
            //         <li data-toggle="collapse" data-target="#navbar">
            //           <Link to="/breakfastmenu">Breakfast</Link>
            //         </li>
            //         <li data-toggle="collapse" data-target="#navbar">
            //           <Link to="/gallery">Gallery</Link>
            //         </li>
            //         <li data-toggle="collapse" data-target="#navbar">
            //           <Link to="/events">Events</Link>
            //         </li>
            //       </ul>
            //     </div>

            //     <div className='row hidden-xs'>
            //       <ul className="nav navbar-nav" >

                    
            //           <li id='logopadding'>
            //             <Link to="/" id='logopadding'><img className="pull-left" id='logo' src="./assets/mayerslogonew.png" /></Link>
            //           </li> 
            //           <li className='navtext'><Link to="/breakfastmenu">Breakfast</Link></li>
            //           <li className='navtext'><Link to="/lunchmenu">Lunch</Link></li>
            //           <li className='navtext'><Link to="/cakemenu">Cakes</Link></li>
            //           <li className='navtext'><Link to="/pastrymenu">Pastries</Link></li>
            //           <li className='navtext'><Link to="/events">Events</Link></li>
            //           <li className='navtext'><Link to="/gallery">Gallery</Link></li>
            //           <li className='navtext'><Link to="/contact">Contact</Link></li>
            //           <li className='navtext'><a href="https://www.instagram.com/mayersbakery/">
            //             <i className="fa fa-instagram" alt='instagram' aria-hidden="true"></i></a>
            //           </li>   
                    
            //       </ul>  
            //     </div>
            
            // </nav>
    )
  }

};

export default Navbar;
