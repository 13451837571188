import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class CakePage extends Component {
  render(){
    window.scrollTo(0, 0);
    return(
      <div className='card cardPad'>

        <h1 className='title pacific'>Cakes</h1>
         
       <div className="mobile">
          <img src='./assets/sqparisiantorte.jpg' className=' img-circle soloImg mainImg' alt="parisian torte"/>
       </div>
       
       <div className='photoRow desktop'>  
          <img className="img-circle" src="./assets/sqcake1.jpg" alt="cake"/>
          <img className="img-circle" src="./assets/sqcake4.jpg" alt="cake"/>
          <img className="img-circle desktop-m" src="./assets/sqcake3.jpg" alt="cake"/>
          <img className="img-circle desktop-l" src="./assets/sqcake7.jpg" alt="cake"/>
       </div>

        <div className='center-flex'>
            <p className='text-center blurbs'>The most beautiful and delicious cakes, pastries and seasonal specialities since 1965</p>
            <p className='text-center blurbs'>To order a custom cake please call us at (310) 377-7846 </p>
            <p className='text-center blurbs'>Want to see more? checkout our <Link to='/gallery'>gallery!</Link></p> 
        </div>
        <br/>
        <div className='cakeCard text-center'>
          <h3 className='text-center title'>Custom Cakes</h3>
          <p className='text-center blurbs'>
            The perfect cake custom made for your special event
          </p>
          <br/>
          <div>
            <h3 className='title'>Cakes</h3>
            <div className='subMenu blurbs'>
              <div> <p>White Cake</p> </div>
              <div> <p>Chocolate Cake</p> </div>
              <div> <p>Half White Half Chocolate Cake</p> </div>
              <div> <p>Banana Cake</p> </div>
              <div> <p>Spice Cake</p> </div>
              <div> <p>Marble Cake</p> </div>
              <div> <p>Carrot Cake</p> </div>
              <div> <p>Lemon Cake</p> </div>
              <div> <p>Red Velvet Cake</p> </div>
              <div> <p>Chiffon Cake</p> </div>
              <div> <p>Chocolate Chiffon Cake</p> </div>
              <div> <p>Cheesecake</p> </div>
            </div>
          </div>
          <div>
            <h3 className='title'>Fillings</h3>
            <div className='subMenu blurbs'>
              <div> <p>Fudge</p> </div>
              <div> <p>Vanilla Custard</p> </div>
              <div> <p>French Buttercream</p> </div>
              <div> <p>Vanilla Buttercream</p> </div>
              <div> <p>Chocolate Buttercream</p> </div>
              <div> <p>Mocha Buttercream</p> </div>
              <div> <p>Lemon Preserve</p> </div>
              <div> <p>Raspberry Preserve</p> </div>
              <div> <p>Pineapple Preserve</p> </div>
              <div> <p>Lemon Mousse</p> </div>
              <div> <p>Raspberry Mousse</p> </div>
              <div> <p>Strawberry Mousse</p> </div>
              <div> <p>Fresh Strawberries & Whipped Cream</p> </div>
              <div> <p>Fresh Bananas & Whipped Cream</p> </div>
              <div> <p>Mango Mousse</p> </div>
              <div> <p>Passion Fruit Mousse</p> </div>
              <div> <p>Hazelnut Mousse</p> </div>
              <div> <p>Chocolate Mousse</p> </div>
              <div> <p>Peanut Butter Mousse</p> </div>
              <div> <p>Mint Chocolate Chip</p> </div>
              <div> <p>Toffee Crunch</p> </div>
              <div> <p>Bavarian Cream</p> </div>
              <div> <p>Rum Custard</p> </div>
              <div> <p>Grand Mariner Custard</p> </div>
              <div> <p>German Chocolate</p> </div>
              <div> <p>Cream Cheese</p> </div>
            </div>
          </div>
          <div>
            <h3 className='title'>Frostings</h3>
            <div className='subMenu blurbs'>
              <div> <p>Fudge</p> </div>
              <div> <p>French Buttercream</p> </div>
              <div> <p>Vanilla Buttercream</p> </div>
              <div> <p>Chocolate Buttercream</p> </div>
              <div> <p>Mocha Buttercream</p> </div>
              <div> <p>Whipped Cream</p> </div>
              <div> <p>Cream Cheese</p> </div>
              <div> <p>Chocolate Mousse</p> </div>
              <div> <p>Marzipan</p> </div>
              <div> <p>White Chocolate Ganache</p> </div>
              <div> <p>Dark Chocolate Ganache</p> </div>
            </div>
          </div>

        </div>
        
        <div className='cakeCard'>
          
          <h2 className='text-center title'>In-Store Cakes</h2>
          <p className='text-center blurbs'>
            A variety of these cakes are always avalible in the bakery, but if you have something specific in mind it is best to phone in an order in advance.
          </p>
          <br/>

          <div className="subMenu">
            <div className="CakeMenuItem" >
                <h3 className='text-center title'>Sacher Torte</h3>
                <p className=" text-center">
                  Layers of chocolate cake, filled with raspberry ,and chocolate mousse, coated with dark chocolate ganache
                  <br/>8 inch round
                </p>
            </div>
              
            <div className="CakeMenuItem">
              <h3 className='text-center title'>Parisian Torte</h3>
              <p className=' text-center blurbs'>  
                Layers of white cake with raspberry and bavarian cream filling, coated with white chocolate ganache
                <br/>8 inch round 
              </p>
            </div>
          
            
            <div className="CakeMenuItem">
              <h3 className='text-center title'>Shadow Torte</h3>
              <p className=' text-center blurbs'>  
                Half white cake, half chocolate with fudge filling, frosted with vanilla buttercream with chocolate ganache dripping down the sides
                <br/>8 inch round
              </p>
            </div>
              
            <div className="CakeMenuItem">
              <h3 className='text-center title'> Carrot Cake</h3>
              <p className=' text-center blurbs'>  
                Moist Carrot Cake with Cream Cheese filling and frosting and sides coated with toasted crunch.
                <br/>8 inch round
              </p>
            </div>
              
            <div className="CakeMenuItem">
              <h3 className='text-center title'>Fudge Torte</h3>
              <p className=' text-center blurbs'>  
                Rich Chocolate Cake with thick Fudge filling and frosting.
                <br/>8 inch round
              </p>
            </div>
              
            <div className="CakeMenuItem">
              <h3 className='text-center title'> Rev Velvet Cake</h3>
              <p className=' text-center blurbs'>  
                Our delicious Red Velvet Cake with Cream Cheese filling and frosting
                <br/>8 inch round
              </p>
            </div>
              
            <div className="CakeMenuItem">
              <h3 className='text-center title'> Bannana Cake</h3>
              <p className=' text-center blurbs'>  
                Our tasty moist Banana Cake made with fresh ripe bananas filled and frosted with Cream Cheese 
                <br/>8 inch round
              </p>
            </div>
              
            <div className="CakeMenuItem">
              <h3 className='text-center title'> German Chocolate Cake</h3>
              <p className=' text-center blurbs'>  
                Rich Chocolate Cake with our German Chocolate filling and topping with a Fudge frosting.
                <br/>8 inch round
              </p>
            </div>
              
            <div className="CakeMenuItem">
              <h3 className='text-center title'> Confetti Birthday Cake</h3>
              <p className=' text-center blurbs'>  
              The classic Birthday Cake, Half White and Half Chocolate with a Fudge filling, Vanilla buttercream frosting and sprinkled with confetti.
              <br/>8 inch round
              </p>
            </div>
              
            <div className="CakeMenuItem">
              <h3 className='text-center title'> Cheesecake </h3>
              <p className=' text-center blurbs'>  
                Rich and creamy cheesecake, avalible with various seasonal fruit toppings
                <br/>8 inch round
              </p>
            </div>
              
            <div className="CakeMenuItem">
              <h3 className='text-center title'> Chocolate Chip Cheesecake</h3>
              <p className=' text-center blurbs'> 
                A balance of chocolate and cheesecake, Our rich and creamy cheesecake is filled with chocolate chips
                <br/>8 inch round
              </p>
            </div>
              
            <div className="CakeMenuItem">
              <h3 className='text-center title'> Strawberry Cheesecake</h3>
              <p className=' text-center blurbs'>  
                Rich and creamy cheesecake topped with ripe strawberries
                <br/>8 inch round
              </p>
            </div>
              
            <div className="CakeMenuItem">
              <h3 className='text-center title'> Strawberry Boston Torte</h3>
              <p className=' text-center blurbs'> 
                moist yellow chiffon cake filled with bavarian cream and fresh strawberries, iced with fresh whippedcream and topped with whole strawberries 
                <br/>8 inch round
              </p>
            </div>
              
            <div className="CakeMenuItem">
              <h3 className='text-center title'> Blackforest Cake</h3>
              <p className=' text-center blurbs'>  
                Moist chocolate cake filled with cherries and whipped cream, iced with french buttercream and topped with maraschino cherries and shaved chocolate 
                <br/>8 inch round
              </p>
            </div>
          </div>
        </div>
        
      </div>
      )}
  };

  export default  CakePage;