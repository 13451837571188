import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Food extends Component {
  render(){
    return(
      <div className="tile">

        <div className="mobile">
          <h1 className='title pacific'>Breakfast and Lunch</h1> 
          <img className="img-circle soloImg" src="/assets/PANINI.jpg" alt="panini"/>
        </div>

        <div className='desktop desktop-h homeImgBox'>
          <span> <img src="/assets/sand1.jpg"alt="sandwich"/> </span>
          <span className="desktop-l"> <img src="/assets/panini2.jpg" alt="panini"/> </span>
          <span> <img src="/assets/panini1.jpg" alt="panini"/> </span>
          <span className="desktop-l"> <img src="/assets/sand2.jpg"alt="sandwich"/> </span>         
        </div>
        
        <div className='mobile-f desktop-h homeTextBox text-center'>      
          <h1 className='title pacific'>Breakfast and Lunch</h1>

          <p className='blurbs btxt'>
           Sandwiches, Soups and Salads for Lunch and Breakfast Sandwiches all day, all fully customizable.
          </p>

          <div className='row text-center'>
            <Link to="/Lunch"><h2 className='title pacific'>Lunch Menu</h2></Link>
            <Link to="/Breakfast"><h2 className='title pacific'>Breakfast Menu</h2></Link>
          </div>
        </div>

      </div>

      )
  }
};


export default Food; 