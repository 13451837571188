import React, { Component } from 'react';
import Map from './Map';



class Footer extends Component{

  render(){
    return(
      <div id='footer'>
        <Map/>
      </div>
      )
  }
};



export default Footer;