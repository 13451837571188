import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import  Home  from './components/Home/Home';
import  NoMatch  from './components/NoMatch';

import Navbar from './components/Navbar';
import Footer from './components/Footer';

import CakePage from "./components/CakePage";
import BreadPage from "./components/BreadPage";
import PastryPage from "./components/PastryPage";
import EventPage from "./components/EventPage";
import ContactPage from "./components/Contact";
import ImgGallery from "./components/ImgGallery";
import BreakfastPage from './components/BreakfastPage';
import LunchPage from './components/LunchPage';
import {Helmet} from "react-helmet";


class App extends Component {
  render() {
    window.scrollTo(0, 0);
    return (
      <Router>
          <div className='App' id='app'>
            <Helmet>
              <meta name="description" 
                  content="Welcome to Mayer's Bakery, established in 1965, Were proud to serve a wide selection of 
                  specialty cakes and pastries for all occasions to the Southbay for over 60 years! Browse our menu's online or call us 
                  to learn about our rotating seasonal items. Getting Married? checkout our events page for frequently asked questions, 
                  or browse our gallery for ideas. When you're ready give us a call to schedule a complimentary cake tasting. Is it your Birthday? 
                  come in to browse our photo albums for inspiration or bring in your own concepts and ideas. From Custom Sculpted Cakes 
                  to Photo Cakes to Confetti and Streamers, with a wide variety of delicious fillings and frostings, we can build you the  
                  perfect cake for your special day." 
              />
              <meta name="google-site-verification" content="bBM7wecsl9C82eKKI6JmJiSTjFxNBkefRbHul16Ezs0" />
            </Helmet>

            <Navbar/>  

            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/breakfast/" component={BreakfastPage} />
              <Route path="/lunch/" component={LunchPage} />
              <Route path="/cakes/" component={CakePage} />
              <Route path="/pastry/" component={PastryPage} />
              <Route path="/bread/" component={BreadPage} />
              <Route path="/gallery/" component={ImgGallery} />
              <Route path="/events/" component={EventPage} /> 
              <Route path="/contact/" component={ContactPage} />
              <Route component={NoMatch} /> 
            </Switch>

            <Footer/>
            <a href="https://www.ghostburger.ooo" alt="a secret link to ghostburger">.</a>
          </div>
      </Router>
    );
  }
}

export default App;
