import React, { Component } from 'react';



class LunchPage extends Component{
  render(){
    window.scrollTo(0, 0);
    return(
      <div className='card cardPad'>
          <h1 className="title pacific">Lunch</h1>

          <div className="mobile">
            <img src='./assets/PANINI.jpg' className=' img-circle soloImg mainImg' alt="panini"/>
          </div>

          <div className='photoRow desktop'>  
            <img className="img-circle" src="./assets/PANINI.jpg" alt="panini"/>
            <img className="img-circle" src="./assets/sqwrap.jpg" alt="wrap"/>
            <img className="img-circle desktop-m" src="./assets/sand1.jpg" alt="sandwich"/>
            <img className="img-circle desktop-l" src="./assets/sand2.jpg" alt="sandwich"/>
          </div>

          <h3 className='text-center title'>Sandwiches</h3>
          
          <div className="menu">    
              <div className='menuItem'>
                <h4 className='title'>Italian Combo</h4>
                <p className='blurbs'>Black Forest Ham, Genoa Salami, Mortadella, Provolone Cheese, Black Olives, Chopped Pepperoncini, Lettuce, Tomato and Italian Dressing on White or Wheat Roll.</p>
              </div>
              
              <div className='menuItem'>
                <h4 className='title'>Tuna</h4> 
                <p className='blurbs'>White Meat Tuna, Cheddar Cheese, and Tomato & Mayonnaise on choice of bread.</p>
              </div>

              <div className='menuItem'>
                <h4 className='title'>Ham and Turkey</h4> 
                <p className='blurbs'>Black Forest Ham, Oven Gold Turkey, Smoked Gruyere Cheese, Lettuce, Mayonnaise, Spicy Mustard on White or Wheat Roll.</p>
              </div>
              
              <div className='menuItem'>
                <h4 className='title'>Vegetarian</h4> 
                <p className='blurbs'>Cheddar cheese, Swiss cheese, Avocado, Onion, Lettuce, Tomato, Olives, Pepperoncini, Mustard, Mayonnaise on White or Wheat Roll.</p>
              </div>
            </div>
            
            <h3 className='text-center title'>Paninis</h3>     
            
            <div className="menu">
              <div className='menuItem'>
                <h4 className='title'>Times Square</h4> 
                <p className='blurbs'>Cracked Pepper Turkey, Bacon, Swiss Cheese, With 1000 Island Dressing on Panini Bread</p>
              </div>

              <div className='menuItem'>
                <h4 className='title'>Wild West</h4> 
                <p className='blurbs'>Hot Roasted Beef, Cheddar Cheese, Sliced Onions, Smothered in BBQ on Panini Bread</p>
              </div>    

              <div className='menuItem'>
                <h4 className='title'>Hot Pastrami</h4> 
                <p className='blurbs'>New York Pastrami Brisket with Melted Swiss Cheese and Spicy Mustard on Panini Bread</p> 
              </div>
            </div>

            <h3 className='text-center title'>Salads</h3>  
              <p className='text-center blurbs'>All Salads Include: Baby Lettuce, Romaine Lettuce, Baby Escarole, Onions, Black Olives, Green Stuffed Olives, Pepperoncini Crouton, Artichoke Hearts, Sun Dried Tomatoes, and Dressing.<br></br> Salad Dressings: Italian, Ranch, 1000 Island, Vinagarette.
              </p>
            
            <div className="menu">
              <div className='menuItem'>
                <h4 className='title'>Times Square Salad</h4> 
                <p className='blurbs'>Cracked Pepper Turkey, Bacon, Swiss Cheese</p>
              </div>

              <div className='menuItem'>
                <h4 className='title'>Wild West Salad</h4> 
                <p className='blurbs'>Roast Beef, Cheddar Cheese</p>
              </div>

              <div className='menuItem'>
                <h4 className='title'>Pastrami Salad</h4> 
                <p className='blurbs'>Pastrami, Swiss Cheese</p>
              </div>

              <div className='menuItem'>
                <h4 className='title'>Italian Combo Salad</h4> 
                <p className='blurbs'>Black Forest Ham, Genoa, Mortadella, Provolone Cheese</p>
              </div>

              <div className='menuItem'>
                <h4 className='title'>Ham & Turkey Salad</h4> 
                <p className='blurbs'>Black Forest Ham, Oven Gold Turkey, Smoked Gruyere Cheese</p>
              </div>

              <div className='menuItem'>
                <h4 className='title'>Tuna Salad</h4> 
                <p className='blurbs'>White meat Tuna, Cheddar Cheese</p>
              </div>

              <div className='menuItem'>
                <h4 className='title'>Vegetarian Salad</h4> 
                <p className='blurbs'>Cheddar Cheese, Swiss Cheese, Avocado</p>
              </div>
            </div>
      </div>
      )}
  };


export default LunchPage;