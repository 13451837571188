import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Event extends Component {
  render() {
    return(
      <div className="tile">
        <div className="mobile"> 
          <h1 className="title pacific ">Events</h1> 
          <img className="img-circle soloImg mainImg" src="./assets/sqblossomtier.jpg" alt="tier cake"/>
        </div>
        
        <div className='desktop desktop-h homeImgBox'>
          <span>
            <img src="/assets/sqblossomtier.jpg" alt="tier cake"/>
          </span>
        <span className="desktop-l" > <img src="/assets/sqshelltier.jpg" alt="tier cake"/> </span>
          <span>
            <img src="/assets/sqflowertier.jpg" alt="tier cake"/>
          </span>
          <span className="desktop-l" >
           <img src="/assets/sqcreamtier.jpg" alt="tier cake"/>
          </span>        
        </div>

        <div className='homeTextBox mobile-f desktop-h text-center'>      
          <h1 className='desktop title pacific'>Events</h1>

          <p className='blurbs btxt'> 
            We cater to a large variety of special events. From Weddings and Anniversarys to Birthdays and Graduations, Let us put together the sweets of your dreams for your special day.
          </p>

          <div className='row text-center'>
            <Link to="/events"><h2 className='title pacific'>Learn More</h2></Link>
          </div>
        </div>

      </div>

      )
  }
};


export default Event; 