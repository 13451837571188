import React, { Component } from 'react';

class EventPage extends Component{

  render() {
    window.scrollTo(0, 0);
    return(
      <div className='card cardPad' >

        <h1 className='text-center title pacific'>Events</h1>
        
        <div className="mobile">
          <img className="img-circle soloImg mainImg" src="./assets/sqblossomtier.jpg" alt="tier cake"/>
        </div>

        <div className="eventPhotoRow">
          <img className="desktop" src="./assets/sqblossomtier.jpg" alt="tier cake"/>
          <img className="desktop" src="./assets/sqshelltier.jpg" alt="tier cake"/>
          <img className="desktop-l" src="./assets/sqflowertier.jpg" alt="tier cake"/>
        </div>

        <div className='blurbs desktopEventBlurb'>
          <p> Weddings </p>
          <p> Birthdays </p>
          <p> Anniversaries </p>
          <p> Special Occasions </p>
        </div>  

        <h3 className='text-center title'>Wedding Services</h3>

        <p className='text-center'>
            Let us make you the perfect desserts and pastries for your event. We cater the best desserts and pastries for Weddings, Anniversaries or any special occasion. Come on in to Mayer's and see what we can craft for you!
        </p>
        <div className='blurbs ' id='eventText'>
            
            <b>May I sample Mayer's wedding cakes?</b>
            <p>Yes! Cake tasting appointments are available at Mayer's Bakery.
            Brides may visit by appointment daily 10 AM to 2 PM Monday through Saturday.</p>
            
            <b>I'm just looking...can I come in without an appointment?</b>
            <p>Yes! At Mayer's Bakery, there are always friendly and helpful wedding cake 
            specialists available to answer your questions. Cake samples, however, are made 
            fresh to your specifications and are not available without an appointment.</p>
            
            
            <b>My wedding is on a Sunday...does Mayer's deliver?</b>
            <p>Yes! We deliver wedding cakes directly to your wedding reception seven days a week</p>
            
            <b>Tell me more about your delivery service.</b>
            <p>Mayer's Bakery is proud to offer problem-free wedding cake delivery to a wide 
            area including the entire South Bay, greater Los Angeles and parts of South 
            Orange County. We will coordinate delivery time with your event professionals to 
            ensure that your wedding cake is assembled properly by our staff. Cost for 
            delivery depends upon location.</p>

            <b>I want something custom...can I have it?</b>
            <p>Of course! EVERY wedding cake we make is custom to your order. We are happy to 
            modify our existing styles to meet your needs, or feel free to bring in your own 
            photographs or sketches. It's easy to make the cake of your dreams a reality!</p>
            
            <b>I'm shopping around...when do I need to decide?</b>
            <p>Because all of our wedding cakes are made fresh to your order, we will not "book
            up" or turn brides away. Final confirmation and payment in full is required two
            weeks prior to your wedding date.</p>
            
            <b className='text-center'>For additional information, or to schedule a cake tasting appointment, please call (310) 377-7846</b>
        
        </div>
      </div>
    )
  }

};


export default EventPage;