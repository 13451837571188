import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Baked extends Component{
  render(){
    return(
      <div className="tile">
        <div className="mobile">
          <h1 className="title pacific">Baked Goods</h1> 
          <img className="img-circle soloImg" src="/assets/sqcake7.jpg" alt="slice of napoleon cake"/>
        </div>

        <div className='mobile-f desktop-h text-center homeTextBox'>      
          <h1 className='desktop pacific'>Baked Goods</h1> 
                    
          <p className='blurbs btxt'> A great variety of baked goods to choose from! <br/>
            Danishes, Muffins, Cookies, Breads and More! 
          </p>

          <div className='row text-center'>
            <Link to="/Pastry" className='mobile-h'>
              <h2 className='title pacific'>Pastry Menu</h2>
            </Link>
            <Link to="/Bread" className='mobile-h'>
              <h2 className='title pacific'>Bread Menu</h2>
            </Link>
          </div>       
        </div>  
        
        <div className='desktop desktop-h homeImgBox'>
          <span> <img  src="/assets/sqbearclaw.jpg" alt="bearclaw pastry"/> </span>
          <span className="desktop-l"> <img  src="/assets/sqchocmuffin.jpg" alt="chocoate chip muffin"/> </span>
          <span> <img  src="/assets/sqchoccroissant.jpg" alt="chocolate croissant"/> </span>
          <span className="desktop-l"> <img  src="/assets/sqbluescone.jpg" alt="blueberry scone"/> </span>         
        </div>
          
      </div>

      )
  }
};


export default Baked;