import React, { Component } from "react";



class PastryPage extends Component {
  render(){
    window.scrollTo(0, 0);
    return(
      <div className="card cardPad">

        <h1 className="text-center pacific" >Pastry</h1>
       
        <div className="mobile">
          <img src="../assets/sqdanish.jpg" className="img-circle menuImg mainImg soloImg" alt="cherry danish" />
        </div>
        
        <div className='photoRow desktop'>  
            <img className="img-circle" src="./assets/bearclaw.jpg" alt="sprinkle cupcake"/>
            <img className="img-circle" src="./assets/danish.jpg" alt="mocha rolls"/>
            <img className="img-circle desktop-m" src="./assets/choccroissant.jpg" alt="fruit tart"/>
            <img className="img-circle desktop-l" src="./assets/bluescone.jpg" alt="key lime tart"/>
        </div>
        
        <h2 className="text-center title">Danish</h2>
        <div className="subMenu blurbs">
          <div className="text-center" >
            <p>Apple</p>
          </div>
          <div className="text-center">
            <p>Cherry</p>
          </div>
          <div className="text-center">
            <p>Raspberry</p>
          </div>
          <div className="text-center">
            <p>Coconut Custard</p>
          </div>
          <div className="text-center">
            <p>Cream Cheese</p>
          </div>
          <div className="text-center">
            <p>Pecan Round</p>
          </div>
          <div className="text-center">
            <p>Pecan Bar</p>
          </div>
          <div className="text-center">
            <p>Bear Claw</p>
          </div>
          <div className="text-center">
            <p>Butter Horn</p>
          </div>
          <div className="text-center">
            <p>Figure 8</p>
          </div>
          <div className="text-center">
            <p>Cream Cheese</p>
          </div>
          <div className="text-center">
            <p>Cherry Blossom</p>
          </div>
          <div className="text-center">
            <p>Elephant Ear</p>
          </div>
          <div className="text-center">
            <p>Apple Turnover</p>
          </div>
          <div className="text-center">
            <p>Raspberry Turnover</p>
          </div>
          <div className="text-center">
            <p>Apricot Creamcheese Pastry</p>
          </div>
          <div className="text-center">
            <p>Raspberry Creamcheese Pastry</p>
          </div>
          <div className="text-center">
            <p>Cinnamon Roll</p>
          </div>
        </div>

        <div className="mobile">
          <img src="./assets/sqchoccroissant.jpg" className="img-circle soloImg mainImg menuImg" alt="chocolate croissant" />
        </div>
        
        <h2 className="text-center title">Croissants</h2>
        <div className="subMenu blurbs">
          <div className="text-center ">
            <p>Chocolate Croissant</p>
          </div>
          <div className="text-center ">
            <p>Almond Croissant</p>
          </div>
          <div className="text-center ">
            <p>Raspberry Almond Croissant</p>
          </div>
          <div className="text-center ">
            <p>Creamcheese Croissant</p>
          </div>
          <div className="text-center ">
            <p>Strawberry Cream Croissant</p>
          </div>
        </div>

        <div className="mobile">
         <img src="./assets/sqchocmuffin.jpg" className="img-circle soloImg mainImg menuImg" alt="chocolate chip muffin"/>
        </div>
        
        <h2 className="text-center title">Muffins</h2>
        <div className="subMenu blurbs">
          <div className="text-center">
            <p>Chocolate Chip Muffin</p>
          </div>
          <div className="text-center">
            <p>Blueberry Muffin</p>
          </div>
          <div className="text-center">
            <p>Cinnamon Sourcream Muffin</p>
          </div>
          <div className="text-center">
            <p>Cranberry Orange Nut Muffin</p>
          </div>
          <div className="text-center">
            <p>Pecan Muffin</p>
          </div>
          <div className="text-center">
            <p>OatBran Muffin</p>
          </div>
        </div>

        <div className="mobile">
          <img src="./assets/sqbluescone.jpg" className="img-circle soloImg mainImg menuImg" alt="blueberry scone" />
        </div>

        <h2 className="text-center title">Scones</h2>
        <div className="subMenu blurbs">
          <div className="text-center">
            <p>Blueberry Scone</p>
          </div>
          <div className="text-center">
            <p>Chocolate Chip Scone</p>
          </div>
          <div className="text-center">
            <p>Almond Scone</p>
          </div>
          <div className="text-center">
            <p>Cranberry Scone</p>
          </div>
          <div className="text-center">
            <p>Oatbran Scone</p>
          </div>
          <div className="text-center">
            <p>White Raisin Scone</p>
          </div>
        </div>

        <h2 className="text-center title">Pies</h2>
        <div className="subMenu blurbs">
          <div className="text-center">
            <p>Old Fashioned Apple Pie</p>
          </div>
          <div className="text-center">
            <p>Dutch Apple Pie</p>
          </div>
          <div className="text-center">
            <p>Classic Pumpkin Pie</p>
          </div>
          <div className="text-center">
            <p>Pumpkin Pie with Whipped Cream</p>
          </div>
          <div className="text-center">
            <p>Pumpkin Chiffon Pie</p>
          </div>
          <div className="text-center">
            <p>Pecan Pie</p>
          </div>
          <div className="text-center">
            <p>Cherry Pie</p>
          </div>
          <div className="text-center">
            <p>Mince Pie</p>
          </div>
        </div>

        <h2 className="text-center title">Loaves</h2>
        <div className="subMenu blurbs">
          <div className="text-center">
            <p>Chocolate Chip</p>
          </div>
          <div className="text-center">
            <p>Double Chocolate</p>
          </div>
          <div className="text-center">
            <p>Butter Pecan</p>
          </div>
          <div className="text-center">
            <p>Sourcream Cinnamon</p>
          </div>
          <div className="text-center">
            <p>Sourcream Blueberry</p>
          </div>
          <div className="text-center">
            <p>Bannana Bread</p>
          </div>
          <div className="text-center">
            <p>Cranberry Orange</p>
          </div>
          <div className="text-center">
            <p>Plain</p>
          </div>
        </div>
        
        <div className="mobile">
         <img src="./assets/mmcookies.jpg" className=" img-circle soloImg mainImg menuImg" alt="chcolate chip cookie" />
        </div>
        
        <h2 className="text-center title">Cookies</h2>
        <div className="subMenu blurbs">
          <div className="text-center">
            <p>Chocolate Chip Cookie</p>
          </div>
          <div className="text-center">
            <p>M&M Cookie</p>
          </div>
          <div className="text-center">
            <p>Peanut Butter Cookie</p>
          </div>
          <div className="text-center">
            <p>SnickerDoodle Cookie</p>
          </div>
        </div>
        
        <div className="mobile">
         <img src="./assets/sqsprinkle1.jpg" className="img-circle soloImg mainImg menuImg" alt="sprinkle cupcake"/>
        </div>
        
        <h2 className="text-center title">Cupcakes</h2>
        <div className="subMenu blurbs">
          <div className="text-center">
            <p>Sprinkle Cupcakes</p>
            <p>chocolate or vanilla</p>
          </div>
          <div className="text-center">
            <p>Floral Cupcakes</p>
            <p>chocolate or vanilla</p>
          </div>
          <div className="text-center">
            <p>Character Cupcakes</p>
            <p>chocolate or vanilla</p>
          </div>
          <div className="text-center">
            <p>Holiday Cupcakes</p>
            <p>chocolate or vanilla</p>
          </div>
        </div>

        <div className="mobile">
         <img src="./assets/sqcake7.jpg" className="img-circle soloImg mainImg menuImg" alt="cake" /> 
        </div>
        <h2 className="text-center title">Cake Slices</h2>
        <div className="subMenu blurbs">
          <div className="text-center">
            <p>Chocolate Fudge</p>
          </div>
          <div className="text-center">
            <p>Tiramisu</p>
          </div>
          <div className="text-center">
            <p>CheeseCake</p>
          </div>
        </div>

        <div className="mobile">
         <img src="./assets/sqeclair.jpg" className=" img-circle soloImg mainImg menuImg" alt="eclair"/> 
        </div>

        <h2 className="text-center title">Dessert Pastries</h2>
        <div className= "subMenu blurbs">
          <div className="text-center">
            <p>Cream Puffs</p>
          </div>
          <div className="text-center">
            <p>Eclairs</p>
            <p>Reg or Mini</p>
          </div>
          <div className="text-center">
            <p>Large Fruit Tart</p>
          </div>
          <div className="text-center">
            <p>Individual Fruit Tart</p>
          </div>
        </div>

        <p className="center-block text-center blurbs">
          Please note that some items are not always avalible for in store pick-up, but almost anything can be special-ordered.
          To check avalibility or place a special order, please call us at (310) 377-7846.
        </p>

      </div>
      )}
  };


export default  PastryPage;