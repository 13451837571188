import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Cakes extends Component {
  render(){
    return(
    <div className="tile">

      <div className="mobile"> 
        <h1 className='mobile title pacific'>Cakes</h1> 
        <img className="img-circle soloImg" src="/assets/sqcake5.jpg" alt="cake"/>
      </div>

      <div className='mobile-f desktop-h text-center homeTextBox'> 
        <h1 className='pacific'>Cakes</h1>
        <p className='blurbs btxt' >
            The perfect cakes for every occasion<br/><br/> Weddings <br/> Anniversaries <br/> Birthdays <br/>
        </p>
  
        <div className='row'> 
          <Link to="/cakes">
            <h2 className='text-center pacific'>Cake Menu</h2>
          </Link>
        </div> 
      </div>
       
      <div className="desktop desktop-h homeImgBox">
       <span> <img src="./assets/sqcake3.jpg" alt="cake"/> </span>
       <span className="desktop-l"> <img src="./assets/sqcake4.jpg" alt="cake"/> </span>
       <span> <img src="./assets/sqchoccheesecake.jpg" alt="cake"/> </span>
       <span className="desktop-l"> <img src="./assets/sqcake1.jpg" alt="cake"/> </span>         
      </div>  
            
    </div>

    )
  }
};


export default  Cakes;