import React, { Component } from 'react';




class NoMatch extends Component {
  render(){
    window.scrollTo(0, 0);
    return(

      <div className='card shortPage' id="noMatch">
        <h1 className='text-center'>Whoah... </h1>           

        <p className='text-center'>I'm not sure how to tell you this...<br/> the page you were looking for doesn't exist...<br/> So heres a picture of a cupcake instead!</p>
          
        <img className="img-circle soloImg mainImg" src="./assets/sqsprinkle1.jpg" alt="sprinkle cupcake"/>  
      </div>
      
      )
  }
};


export default NoMatch;