import React, { Component } from 'react';

class Intro extends Component{
  render(){
    return(
      <div id="Intro">
        <div className="mobile">
          <img className="img-circle soloImg" src="./assets/squaredcreampuff.jpg" alt="cream puff"/>
        </div>

        <div className='photoRow desktop'>  
            <img className="img-circle" src="./assets/sqsprinkle1.jpg" alt="sprinkle cupcake"/>
            <img className="img-circle" src="./assets/sqmocharolls.jpg" alt="mocha rolls"/>
            <img className="img-circle desktop-m" src="./assets/sqtart5.jpg" alt="fruit tart"/>
            <img className="img-circle desktop-l" src="./assets/sqlimetart.jpg" alt="key lime tart"/>
        </div>
        <h1 className='text-center title pacific' id='red'>Welcome to Mayer's Bakery</h1>
        <div className='photoRow desktop '>
            <img className="img-circle" src="./assets/sqchoccroissant.jpg" alt="chocolate croissant"/>
            <img className="img-circle" src="./assets/sqeclair.jpg" alt="eclair"/>
            <img className="img-circle desktop-m" src="./assets/sqthumbprints.jpg" alt="thumbprint cookie"/>
            <img className="img-circle desktop-l" src="./assets/sqtoffee.jpg" alt="toffee"/>
        </div>

        <a 
            href="https://www.doordash.com/business/380811/?utm_source=partner-link&utm_medium=website&utm_campaign=380811&utm_content=red-medium"
            target="_blank"
            rel="noopener noreferrer"
            alt="Order Food Delivery with DoorDash" 
            title="Order Food Delivery with DoorDash" 
            style={{'textDecoration': 'none'}}
            id="ddLink"
        >
          <div id="ddButton">
            Order Food Delivery with DoorDash
          </div>
        </a>

      </div>
      )
  }
};


export default Intro;