import React, { Component } from 'react';


class BreadPage extends Component {
  render(){
    window.scrollTo(0, 0);
    return(
        <div className='card cardPad'>

            <h1 className="text-center pacific">Bread</h1>

            <div className="mobile">
                <img src="../assets/sqchallah.jpg" className="img-circle menuImg mainImg soloImg" alt="cherry danish" />
            </div>

            <div className='photoRow desktop'>
                <img className="img-circle" src="./assets/sqchallah.jpg" alt="challah bread" />
                <img className="img-circle" src="./assets/sqbread.jpg" alt="bread basket" />
                <img className="img-circle desktop-m" src="./assets/sqsesamechallah.jpg" alt="sesame challah bread" />
            </div>
            
            <p className='text-center blurbs'>
                A variety of these breads are always avalible in the bakery, but if you have something specific in mind it is best to phone in an order in advance.
            </p>

            <div className="menu">
                <div className="menuItem" >
                    <h3 className='text-center myHeaders'>Challah Breads</h3>
                    <p className="text-center blurbs" id='MenuDesc'>
                        Avalible with Chocolate Chips, Raisins, Sesame Seeds, or Plain.
                        Sizes up to 5 feet long avalible by special order.
                    </p>
                </div>

                <div className="menuItem" >
                    <h3 className='text-center myHeaders'>Loafs</h3>
                    <p className="text-center blurbs" id='MenuDesc'>
                        Whole Wheat Loaf, Sesame Loaf and Seasonal Varieties.
                    </p>
                </div>

                <div className="menuItem" >
                    <h3 className='text-center myHeaders'>Hamburger Buns</h3>
                    <p className="text-center blurbs" id='MenuDesc'>
                        Rich and fluffy, Sold in packs of 4.
                    </p>
                </div>

                <div className="menuItem" >
                    <h3 className='text-center myHeaders'>Hotdog Buns</h3>
                    <p className="text-center blurbs" id='MenuDesc'>
                        Rich and fluffy, Sold in packs of 6.
                    </p>
                </div>
            </div>

            <h2 className='text-center myHeaders'>Dinner Rolls</h2>

            <div className="menu">
                <div className="menuItem" >
                    <h3 className='text-center myHeaders'>Wagon Wheel</h3>
                    <p className="text-center blurbs" id='MenuDesc'>
                        A round dish of dinner rolls, avalible in plain, sesame, poppy, or combination.
                    </p>
                </div>

                <div className="menuItem" >
                    <h3 className='text-center myHeaders'>Parkerhouse Rolls</h3>
                    <p className="text-center blurbs" id='MenuDesc'>
                        a pan of our rich buttery parkerhouse rolls, contains 9 rolls.
                    </p>
                </div>

                <div className="menuItem" >
                    <h3 className='text-center myHeaders'>Silver Dollar Rolls</h3>
                    <p className="text-center blurbs" id='MenuDesc'>
                        Rich dinner rolls, Great for sliders too! contains a dozen rolls.
                    </p>
                </div>
            </div>
        </div>  
      )}
  };

  export default BreadPage;